const languages = [
    "Afrikaans",
    "Amharic",
    "Arabic",
    "Azerbaijani",
    "Bulgarian",
    "Bangla",
    "Bengali",
    "Bosnian",
    "Catalan",
    "Czech",
    "Welsh",
    "Danish",
    "German",
    "Greek",
    "English",
    "Spanish",
    "Estonian",
    "Basque",
    "Persian",
    "Finnish",
    "Filipino",
    "French",
    "Irish",
    "Galician",
    "Gujarati",
    "Hebrew",
    "Hindi",
    "Croatian",
    "Hungarian",
    "Armenian",
    "Indonesian",
    "Icelandic",
    "Italian",
    "Japanese",
    "Javanese",
    "Georgian",
    "Kazakh",
    "Khmer",
    "Kannada",
    "Korean",
    "Lao",
    "Lithuanian",
    "Latvian",
    "Macedonian",
    "Malayalam",
    "Mongolian",
    "Marathi",
    "Malay",
    "Maltese",
    "Burmese",
    "Norwegian Bokmål",
    "Nepali",
    "Dutch",
    "Polish",
    "Pashto",
    "Portuguese",
    "Romanian",
    "Russian",
    "Sinhala",
    "Slovak",
    "Slovenian",
    "Somali",
    "Albanian",
    "Serbian",
    "Sundanese",
    "Swedish",
    "Swahili",
    "Tamil",
    "Telugu",
    "Thai",
    "Turkish",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Vietnamese",
    "Chinese",
    "Zulu",
];
const lang2abb = {
    Afrikaans: "af",
    Amharic: "am",
    Arabic: "ar",
    Azerbaijani: "az",
    Bulgarian: "bg",
    Bangla: "bn",
    Bengali: "bn",
    Bosnian: "bs",
    Catalan: "ca",
    Czech: "cs",
    Welsh: "cy",
    Danish: "da",
    German: "de",
    Greek: "el",
    English: "en",
    Spanish: "es",
    Estonian: "et",
    Basque: "eu",
    Persian: "fa",
    Finnish: "fi",
    Filipino: "fil",
    French: "fr",
    Irish: "ga",
    Galician: "gl",
    Gujarati: "gu",
    Hebrew: "he",
    Hindi: "hi",
    Croatian: "hr",
    Hungarian: "hu",
    Armenian: "hy",
    Indonesian: "id",
    Icelandic: "is",
    Italian: "it",
    Japanese: "ja",
    Javanese: "jv",
    Georgian: "ka",
    Kazakh: "kk",
    Khmer: "km",
    Kannada: "kn",
    Korean: "ko",
    Lao: "lo",
    Lithuanian: "lt",
    Latvian: "lv",
    Macedonian: "mk",
    Malayalam: "ml",
    Mongolian: "mn",
    Marathi: "mr",
    Malay: "ms",
    Maltese: "mt",
    Burmese: "my",
    "Norwegian Bokm\u00e5l": "nb",
    Nepali: "ne",
    Dutch: "nl",
    Polish: "pl",
    Pashto: "ps",
    Portuguese: "pt",
    Romanian: "ro",
    Russian: "ru",
    Sinhala: "si",
    Slovak: "sk",
    Slovenian: "sl",
    Somali: "so",
    Albanian: "sq",
    Serbian: "sr",
    Sundanese: "su",
    Swedish: "sv",
    Swahili: "sw",
    Tamil: "ta",
    Telugu: "te",
    Thai: "th",
    Turkish: "tr",
    Ukrainian: "uk",
    Urdu: "ur",
    Uzbek: "uz",
    Vietnamese: "vi",
    Chinese: "zh",
    Zulu: "zu",
};

const lang2name = {
    Afrikaans: ["South Africa"],
    Amharic: ["Ethiopia"],
    Arabic: [
        "United Arab Emirates",
        "Bahrain",
        "Algeria",
        "Egypt",
        "Iraq",
        "Jordan",
        "Kuwait",
        "Lebanon",
        "Libya",
        "Morocco",
        "Oman",
        "Qatar",
        "Saudi Arabia",
        "Syria",
        "Tunisia",
        "Yemen",
    ],
    Azerbaijani: ["Latin, Azerbaijan"],
    Bulgarian: ["Bulgaria"],
    Bangla: ["Bangladesh"],
    Bengali: ["India"],
    Bosnian: ["Bosnia and Herzegovina"],
    Catalan: ["Bosnia and Herzegovina"],
    Czech: ["Czechia"],
    Welsh: ["United Kingdom"],
    Danish: ["Denmark"],
    German: ["Austria", "Switzerland", "Germany"],
    Greek: ["Greece"],
    English: [
        "Australia",
        "Canada",
        "United Kingdom",
        "Hong Kong SAR",
        "Ireland",
        "India",
        "Kenya",
        "Nigeria",
        "New Zealand",
        "Philippines",
        "Singapore",
        "Tanzania",
        "United States",
        "South Africa",
    ],
    Spanish: [
        "Argentina",
        "Bolivia",
        "Chile",
        "Colombia",
        "Costa Rica",
        "Cuba",
        "Dominican Republic",
        "Ecuador",
        "Spain",
        "Equatorial Guinea",
        "Guatemala",
        "Honduras",
        "Mexico",
        "Nicaragua",
        "Panama",
        "Peru",
        "Puerto Rico",
        "Paraguay",
        "El Salvador",
        "United States",
        "Uruguay",
        "Venezuela",
    ],
    Estonian: ["Estonia"],
    Basque: ["Estonia"],
    Persian: ["Iran"],
    Finnish: ["Finland"],
    Filipino: ["Philippines"],
    French: ["Belgium", "Canada", "Switzerland", "France"],
    Irish: ["Ireland"],
    Galician: ["Ireland"],
    Gujarati: ["India"],
    Hebrew: ["Israel"],
    Hindi: ["India"],
    Croatian: ["Croatia"],
    Hungarian: ["Hungary"],
    Armenian: ["Armenia"],
    Indonesian: ["Indonesia"],
    Icelandic: ["Iceland"],
    Italian: ["Italy"],
    Japanese: ["Japan"],
    Javanese: ["Latin, Indonesia"],
    Georgian: ["Georgia"],
    Kazakh: ["Kazakhstan"],
    Khmer: ["Cambodia"],
    Kannada: ["India"],
    Korean: ["Korea"],
    Lao: ["Laos"],
    Lithuanian: ["Lithuania"],
    Latvian: ["Latvia"],
    Macedonian: ["North Macedonia"],
    Malayalam: ["India"],
    Mongolian: ["Mongolia"],
    Marathi: ["India"],
    Malay: ["Malaysia"],
    Maltese: ["Malta"],
    Burmese: ["Myanmar"],
    "Norwegian Bokm\u00e5l": ["Norway"],
    Nepali: ["Nepal"],
    Dutch: ["Belgium", "Netherlands"],
    Polish: ["Poland"],
    Pashto: ["Afghanistan"],
    Portuguese: ["Brazil", "Portugal"],
    Romanian: ["Romania"],
    Russian: ["Russia"],
    Sinhala: ["Sri Lanka"],
    Slovak: ["Slovakia"],
    Slovenian: ["Slovenia"],
    Somali: ["Somalia"],
    Albanian: ["Albania"],
    Serbian: ["Latin, Serbia", "Cyrillic, Serbia"],
    Sundanese: ["Indonesia"],
    Swedish: ["Sweden"],
    Swahili: ["Kenya", "Tanzania"],
    Tamil: ["India", "Sri Lanka", "Malaysia", "Singapore"],
    Telugu: ["India"],
    Thai: ["Thailand"],
    Turkish: ["Turkey"],
    Ukrainian: ["Ukraine"],
    Urdu: ["India", "Pakistan"],
    Uzbek: ["Latin, Uzbekistan"],
    Vietnamese: ["Vietnam"],
    Chinese: [
        "Wu, Simplified",
        "Cantonese, Simplified",
        "Mandarin, Simplified",
        "Guangxi Accent Mandarin, Simplified",
        "Zhongyuan Mandarin Henan, Simplified",
        "Northeastern Mandarin, Simplified",
        "Zhongyuan Mandarin Shaanxi, Simplified",
        "Jilu Mandarin, Simplified",
        "Southwestern Mandarin, Simplified",
        "Cantonese, Traditional",
        "Taiwanese Mandarin, Traditional",
    ],
    Zulu: ["South Africa"],
};

const name2reg = {
    "South Africa": "ZA",
    Ethiopia: "ET",
    "United Arab Emirates": "AE",
    Bahrain: "BH",
    Algeria: "DZ",
    Egypt: "EG",
    Iraq: "IQ",
    Jordan: "JO",
    Kuwait: "KW",
    Lebanon: "LB",
    Libya: "LY",
    Morocco: "MA",
    Oman: "OM",
    Qatar: "QA",
    "Saudi Arabia": "SA",
    Syria: "SY",
    Tunisia: "TN",
    Yemen: "YE",
    "Latin, Azerbaijan": "AZ",
    Bulgaria: "BG",
    Bangladesh: "BD",
    India: "IN",
    "Bosnia and Herzegovina": "BA",
    Czechia: "CZ",
    "United Kingdom": "GB",
    Denmark: "DK",
    Austria: "AT",
    Switzerland: "CH",
    Germany: "DE",
    Greece: "GR",
    Australia: "AU",
    Canada: "CA",
    "Hong Kong SAR": "HK",
    Ireland: "IE",
    Kenya: "KE",
    Nigeria: "NG",
    "New Zealand": "NZ",
    Philippines: "PH",
    Singapore: "SG",
    Tanzania: "TZ",
    "United States": "US",
    Argentina: "AR",
    Bolivia: "BO",
    Chile: "CL",
    Colombia: "CO",
    "Costa Rica": "CR",
    Cuba: "CU",
    "Dominican Republic": "DO",
    Ecuador: "EC",
    Spain: "ES",
    "Equatorial Guinea": "GQ",
    Guatemala: "GT",
    Honduras: "HN",
    Mexico: "MX",
    Nicaragua: "NI",
    Panama: "PA",
    Peru: "PE",
    "Puerto Rico": "PR",
    Paraguay: "PY",
    "El Salvador": "SV",
    Uruguay: "UY",
    Venezuela: "VE",
    Estonia: "EE",
    Iran: "IR",
    Finland: "FI",
    Belgium: "BE",
    France: "FR",
    Israel: "IL",
    Croatia: "HR",
    Hungary: "HU",
    Armenia: "AM",
    Indonesia: "ID",
    Iceland: "IS",
    Italy: "IT",
    Japan: "JP",
    "Latin, Indonesia": "ID",
    Georgia: "GE",
    Kazakhstan: "KZ",
    Cambodia: "KH",
    Korea: "KR",
    Laos: "LA",
    Lithuania: "LT",
    Latvia: "LV",
    "North Macedonia": "MK",
    Mongolia: "MN",
    Malaysia: "MY",
    Malta: "MT",
    Myanmar: "MM",
    Norway: "NO",
    Nepal: "NP",
    Netherlands: "NL",
    Poland: "PL",
    Afghanistan: "AF",
    Brazil: "BR",
    Portugal: "PT",
    Romania: "RO",
    Russia: "RU",
    "Sri Lanka": "LK",
    Slovakia: "SK",
    Slovenia: "SI",
    Somalia: "SO",
    Albania: "AL",
    "Latin, Serbia": "LATN-RS",
    "Cyrillic, Serbia": "RS",
    Sweden: "SE",
    Thailand: "TH",
    Turkey: "TR",
    Ukraine: "UA",
    Pakistan: "PK",
    "Latin, Uzbekistan": "UZ",
    Vietnam: "VN",
    "Wu, Simplified": "CN",
    "Cantonese, Simplified": "CN",
    "Mandarin, Simplified": "CN",
    "Guangxi Accent Mandarin, Simplified": "CN-GUANGXI",
    "Zhongyuan Mandarin Henan, Simplified": "CN-henan",
    "Northeastern Mandarin, Simplified": "CN-liaoning",
    "Zhongyuan Mandarin Shaanxi, Simplified": "CN-shaanxi",
    "Jilu Mandarin, Simplified": "CN-shandong",
    "Southwestern Mandarin, Simplified": "CN-sichuan",
    "Cantonese, Traditional": "HK",
    "Taiwanese Mandarin, Traditional": "TW",
};

const reg2voice = {
    ZA: [
        "af-ZA-AdriNeural",
        "af-ZA-WillemNeural",
        "en-ZA-LeahNeural",
        "en-ZA-LukeNeural",
        "zu-ZA-ThandoNeural",
        "zu-ZA-ThembaNeural",
    ],
    ET: ["am-ET-MekdesNeural", "am-ET-AmehaNeural"],
    AE: ["ar-AE-FatimaNeural", "ar-AE-HamdanNeural"],
    BH: ["ar-BH-LailaNeural", "ar-BH-AliNeural"],
    DZ: ["ar-DZ-AminaNeural", "ar-DZ-IsmaelNeural"],
    EG: ["ar-EG-SalmaNeural", "ar-EG-ShakirNeural"],
    IQ: ["ar-IQ-RanaNeural", "ar-IQ-BasselNeural"],
    JO: ["ar-JO-SanaNeural", "ar-JO-TaimNeural"],
    KW: ["ar-KW-NouraNeural", "ar-KW-FahedNeural"],
    LB: ["ar-LB-LaylaNeural", "ar-LB-RamiNeural"],
    LY: ["ar-LY-ImanNeural", "ar-LY-OmarNeural"],
    MA: ["ar-MA-MounaNeural", "ar-MA-JamalNeural"],
    OM: ["ar-OM-AyshaNeural", "ar-OM-AbdullahNeural"],
    QA: ["ar-QA-AmalNeural", "ar-QA-MoazNeural"],
    SA: ["ar-SA-ZariyahNeural", "ar-SA-HamedNeural"],
    SY: ["ar-SY-AmanyNeural", "ar-SY-LaithNeural"],
    TN: ["ar-TN-ReemNeural", "ar-TN-HediNeural"],
    YE: ["ar-YE-MaryamNeural", "ar-YE-SalehNeural"],
    AZ: ["az-AZ-BanuNeural", "az-AZ-BabekNeural"],
    BG: ["bg-BG-KalinaNeural", "bg-BG-BorislavNeural"],
    BD: ["bn-BD-NabanitaNeural", "bn-BD-PradeepNeural"],
    IN: [
        "bn-IN-TanishaaNeural",
        "bn-IN-BashkarNeural",
        "en-IN-NeerjaNeural",
        "en-IN-PrabhatNeural",
        "gu-IN-DhwaniNeural",
        "gu-IN-NiranjanNeural",
        "hi-IN-SwaraNeural",
        "hi-IN-MadhurNeural",
        "kn-IN-SapnaNeural",
        "kn-IN-GaganNeural",
        "ml-IN-SobhanaNeural",
        "ml-IN-MidhunNeural",
        "mr-IN-AarohiNeural",
        "mr-IN-ManoharNeural",
        "ta-IN-PallaviNeural",
        "ta-IN-ValluvarNeural",
        "te-IN-ShrutiNeural",
        "te-IN-MohanNeural",
        "ur-IN-GulNeural",
        "ur-IN-SalmanNeural",
    ],
    BA: ["bs-BA-VesnaNeural", "bs-BA-GoranNeural"],
    ES: [
        "ca-ES-JoanaNeural",
        "ca-ES-EnricNeural",
        "ca-ES-AlbaNeural",
        "es-ES-ElviraNeural",
        "es-ES-AlvaroNeural",
        "es-ES-AbrilNeural",
        "es-ES-ArnauNeural",
        "es-ES-DarioNeural",
        "es-ES-EliasNeural",
        "es-ES-EstrellaNeural",
        "es-ES-IreneNeural",
        "es-ES-LaiaNeural",
        "es-ES-LiaNeural",
        "es-ES-NilNeural",
        "es-ES-SaulNeural",
        "es-ES-TeoNeural",
        "es-ES-TrianaNeural",
        "es-ES-VeraNeural",
        "es-ES-XimenaNeural",
        "eu-ES-AinhoaNeural",
        "eu-ES-AnderNeural",
        "gl-ES-SabelaNeural",
        "gl-ES-RoiNeural",
    ],
    CZ: ["cs-CZ-VlastaNeural", "cs-CZ-AntoninNeural"],
    GB: [
        "cy-GB-NiaNeural",
        "cy-GB-AledNeural",
        "en-GB-SoniaNeural",
        "en-GB-RyanNeural",
        "en-GB-LibbyNeural",
        "en-GB-AbbiNeural",
        "en-GB-AlfieNeural",
        "en-GB-BellaNeural",
        "en-GB-ElliotNeural",
        "en-GB-EthanNeural",
        "en-GB-HollieNeural",
        "en-GB-MaisieNeural",
        "en-GB-NoahNeural",
        "en-GB-OliverNeural",
        "en-GB-OliviaNeural",
        "en-GB-ThomasNeural",
    ],
    DK: ["da-DK-ChristelNeural", "da-DK-JeppeNeural"],
    AT: ["de-AT-IngridNeural", "de-AT-JonasNeural"],
    CH: [
        "de-CH-LeniNeural",
        "de-CH-JanNeural",
        "fr-CH-ArianeNeural",
        "fr-CH-FabriceNeural",
    ],
    DE: [
        "de-DE-KatjaNeural",
        "de-DE-ConradNeural",
        "de-DE-AmalaNeural",
        "de-DE-BerndNeural",
        "de-DE-ChristophNeural",
        "de-DE-ElkeNeural",
        "de-DE-GiselaNeural",
        "de-DE-KasperNeural",
        "de-DE-KillianNeural",
        "de-DE-KlarissaNeural",
        "de-DE-KlausNeural",
        "de-DE-LouisaNeural",
        "de-DE-MajaNeural",
        "de-DE-RalfNeural",
        "de-DE-SeraphinaNeural",
        "de-DE-TanjaNeural",
    ],
    GR: ["el-GR-AthinaNeural", "el-GR-NestorasNeural"],
    AU: [
        "en-AU-NatashaNeural",
        "en-AU-WilliamNeural",
        "en-AU-AnnetteNeural",
        "en-AU-CarlyNeural",
        "en-AU-DarrenNeural",
        "en-AU-DuncanNeural",
        "en-AU-ElsieNeural",
        "en-AU-FreyaNeural",
        "en-AU-JoanneNeural",
        "en-AU-KenNeural",
        "en-AU-KimNeural",
        "en-AU-NeilNeural",
        "en-AU-TimNeural",
        "en-AU-TinaNeural",
    ],
    CA: [
        "en-CA-ClaraNeural",
        "en-CA-LiamNeural",
        "fr-CA-SylvieNeural",
        "fr-CA-JeanNeural",
        "fr-CA-AntoineNeural",
        "fr-CA-ThierryNeural",
    ],
    HK: [
        "en-HK-YanNeural",
        "en-HK-SamNeural",
        "zh-HK-HiuMaanNeural",
        "zh-HK-WanLungNeural",
        "zh-HK-HiuGaaiNeural",
    ],
    IE: [
        "en-IE-EmilyNeural",
        "en-IE-ConnorNeural",
        "ga-IE-OrlaNeural",
        "ga-IE-ColmNeural",
    ],
    KE: [
        "en-KE-AsiliaNeural",
        "en-KE-ChilembaNeural",
        "sw-KE-ZuriNeural",
        "sw-KE-RafikiNeural",
    ],
    NG: ["en-NG-EzinneNeural", "en-NG-AbeoNeural"],
    NZ: ["en-NZ-MollyNeural", "en-NZ-MitchellNeural"],
    PH: [
        "en-PH-RosaNeural",
        "en-PH-JamesNeural",
        "fil-PH-BlessicaNeural",
        "fil-PH-AngeloNeural",
    ],
    SG: [
        "en-SG-LunaNeural",
        "en-SG-WayneNeural",
        "ta-SG-VenbaNeural",
        "ta-SG-AnbuNeural",
    ],
    TZ: [
        "en-TZ-ImaniNeural",
        "en-TZ-ElimuNeural",
        "sw-TZ-RehemaNeural",
        "sw-TZ-DaudiNeural",
    ],
    US: [
        "en-US-JennyNeural",
        "en-US-GuyNeural",
        "en-US-AriaNeural",
        "en-US-DavisNeural",
        "en-US-AmberNeural",
        "en-US-AnaNeural",
        "en-US-AndrewNeural",
        "en-US-AshleyNeural",
        "en-US-BrandonNeural",
        "en-US-BrianNeural",
        "en-US-ChristopherNeural",
        "en-US-CoraNeural",
        "en-US-ElizabethNeural",
        "en-US-EmmaNeural",
        "en-US-EricNeural",
        "en-US-JacobNeural",
        "en-US-JaneNeural",
        "en-US-JasonNeural",
        "en-US-MichelleNeural",
        "en-US-MonicaNeural",
        "en-US-NancyNeural",
        "en-US-RogerNeural",
        "en-US-SaraNeural",
        "en-US-SteffanNeural",
        "en-US-TonyNeural",
        "es-US-PalomaNeural",
        "es-US-AlonsoNeural",
    ],
    AR: ["es-AR-ElenaNeural", "es-AR-TomasNeural"],
    BO: ["es-BO-SofiaNeural", "es-BO-MarceloNeural"],
    CL: ["es-CL-CatalinaNeural", "es-CL-LorenzoNeural"],
    CO: ["es-CO-SalomeNeural", "es-CO-GonzaloNeural"],
    CR: ["es-CR-MariaNeural", "es-CR-JuanNeural"],
    CU: ["es-CU-BelkysNeural", "es-CU-ManuelNeural"],
    DO: ["es-DO-RamonaNeural", "es-DO-EmilioNeural"],
    EC: ["es-EC-AndreaNeural", "es-EC-LuisNeural"],
    GQ: ["es-GQ-TeresaNeural", "es-GQ-JavierNeural"],
    GT: ["es-GT-MartaNeural", "es-GT-AndresNeural"],
    HN: ["es-HN-KarlaNeural", "es-HN-CarlosNeural"],
    MX: [
        "es-MX-DaliaNeural",
        "es-MX-JorgeNeural",
        "es-MX-BeatrizNeural",
        "es-MX-CandelaNeural",
        "es-MX-CarlotaNeural",
        "es-MX-CecilioNeural",
        "es-MX-GerardoNeural",
        "es-MX-LarissaNeural",
        "es-MX-LibertoNeural",
        "es-MX-LucianoNeural",
        "es-MX-MarinaNeural",
        "es-MX-NuriaNeural",
        "es-MX-PelayoNeural",
        "es-MX-RenataNeural",
        "es-MX-YagoNeural",
    ],
    NI: ["es-NI-YolandaNeural", "es-NI-FedericoNeural"],
    PA: ["es-PA-MargaritaNeural", "es-PA-RobertoNeural"],
    PE: ["es-PE-CamilaNeural", "es-PE-AlexNeural"],
    PR: ["es-PR-KarinaNeural", "es-PR-VictorNeural"],
    PY: ["es-PY-TaniaNeural", "es-PY-MarioNeural"],
    SV: ["es-SV-LorenaNeural", "es-SV-RodrigoNeural"],
    UY: ["es-UY-ValentinaNeural", "es-UY-MateoNeural"],
    VE: ["es-VE-PaolaNeural", "es-VE-SebastianNeural"],
    EE: ["et-EE-AnuNeural", "et-EE-KertNeural"],
    IR: ["fa-IR-DilaraNeural", "fa-IR-FaridNeural"],
    FI: ["fi-FI-SelmaNeural", "fi-FI-HarriNeural", "fi-FI-NooraNeural"],
    BE: [
        "fr-BE-CharlineNeural",
        "fr-BE-GerardNeural",
        "nl-BE-DenaNeural",
        "nl-BE-ArnaudNeural",
    ],
    FR: [
        "fr-FR-DeniseNeural",
        "fr-FR-HenriNeural",
        "fr-FR-AlainNeural",
        "fr-FR-BrigitteNeural",
        "fr-FR-CelesteNeural",
        "fr-FR-ClaudeNeural",
        "fr-FR-CoralieNeural",
        "fr-FR-EloiseNeural",
        "fr-FR-JacquelineNeural",
        "fr-FR-JeromeNeural",
        "fr-FR-JosephineNeural",
        "fr-FR-MauriceNeural",
        "fr-FR-VivienneNeural",
        "fr-FR-YvesNeural",
        "fr-FR-YvetteNeural",
    ],
    IL: ["he-IL-HilaNeural", "he-IL-AvriNeural"],
    HR: ["hr-HR-GabrijelaNeural", "hr-HR-SreckoNeural"],
    HU: ["hu-HU-NoemiNeural", "hu-HU-TamasNeural"],
    AM: ["hy-AM-AnahitNeural", "hy-AM-HaykNeural"],
    ID: [
        "id-ID-GadisNeural",
        "id-ID-ArdiNeural",
        "jv-ID-SitiNeural",
        "jv-ID-DimasNeural",
        "su-ID-TutiNeural",
        "su-ID-JajangNeural",
    ],
    IS: ["is-IS-GudrunNeural", "is-IS-GunnarNeural"],
    IT: [
        "it-IT-ElsaNeural",
        "it-IT-IsabellaNeural",
        "it-IT-DiegoNeural",
        "it-IT-BenignoNeural",
        "it-IT-CalimeroNeural",
        "it-IT-CataldoNeural",
        "it-IT-FabiolaNeural",
        "it-IT-FiammaNeural",
        "it-IT-GianniNeural",
        "it-IT-GiuseppeNeural",
        "it-IT-ImeldaNeural",
        "it-IT-IrmaNeural",
        "it-IT-LisandroNeural",
        "it-IT-PalmiraNeural",
        "it-IT-PierinaNeural",
        "it-IT-RinaldoNeural",
    ],
    JP: [
        "ja-JP-NanamiNeural",
        "ja-JP-KeitaNeural",
        "ja-JP-AoiNeural",
        "ja-JP-DaichiNeural",
        "ja-JP-MayuNeural",
        "ja-JP-NaokiNeural",
        "ja-JP-ShioriNeural",
    ],
    GE: ["ka-GE-EkaNeural", "ka-GE-GiorgiNeural"],
    KZ: ["kk-KZ-AigulNeural", "kk-KZ-DauletNeural"],
    KH: ["km-KH-SreymomNeural", "km-KH-PisethNeural"],
    KR: [
        "ko-KR-SunHiNeural",
        "ko-KR-InJoonNeural",
        "ko-KR-BongJinNeural",
        "ko-KR-GookMinNeural",
        "ko-KR-HyunsuNeural",
        "ko-KR-JiMinNeural",
        "ko-KR-SeoHyeonNeural",
        "ko-KR-SoonBokNeural",
        "ko-KR-YuJinNeural",
    ],
    LA: ["lo-LA-KeomanyNeural", "lo-LA-ChanthavongNeural"],
    LT: ["lt-LT-OnaNeural", "lt-LT-LeonasNeural"],
    LV: ["lv-LV-EveritaNeural", "lv-LV-NilsNeural"],
    MK: ["mk-MK-MarijaNeural", "mk-MK-AleksandarNeural"],
    MN: ["mn-MN-YesuiNeural", "mn-MN-BataaNeural"],
    MY: [
        "ms-MY-YasminNeural",
        "ms-MY-OsmanNeural",
        "ta-MY-KaniNeural",
        "ta-MY-SuryaNeural",
    ],
    MT: ["mt-MT-GraceNeural", "mt-MT-JosephNeural"],
    MM: ["my-MM-NilarNeural", "my-MM-ThihaNeural"],
    NO: ["nb-NO-PernilleNeural", "nb-NO-FinnNeural", "nb-NO-IselinNeural"],
    NP: ["ne-NP-HemkalaNeural", "ne-NP-SagarNeural"],
    NL: ["nl-NL-FennaNeural", "nl-NL-MaartenNeural", "nl-NL-ColetteNeural"],
    PL: ["pl-PL-AgnieszkaNeural", "pl-PL-MarekNeural", "pl-PL-ZofiaNeural"],
    AF: ["ps-AF-LatifaNeural", "ps-AF-GulNawazNeural"],
    BR: [
        "pt-BR-FranciscaNeural",
        "pt-BR-AntonioNeural",
        "pt-BR-BrendaNeural",
        "pt-BR-DonatoNeural",
        "pt-BR-ElzaNeural",
        "pt-BR-FabioNeural",
        "pt-BR-GiovannaNeural",
        "pt-BR-HumbertoNeural",
        "pt-BR-JulioNeural",
        "pt-BR-LeilaNeural",
        "pt-BR-LeticiaNeural",
        "pt-BR-ManuelaNeural",
        "pt-BR-NicolauNeural",
        "pt-BR-ThalitaNeural",
        "pt-BR-ValerioNeural",
        "pt-BR-YaraNeural",
    ],
    PT: ["pt-PT-RaquelNeural", "pt-PT-DuarteNeural", "pt-PT-FernandaNeural"],
    RO: ["ro-RO-AlinaNeural", "ro-RO-EmilNeural"],
    RU: ["ru-RU-SvetlanaNeural", "ru-RU-DmitryNeural", "ru-RU-DariyaNeural"],
    LK: [
        "si-LK-ThiliniNeural",
        "si-LK-SameeraNeural",
        "ta-LK-SaranyaNeural",
        "ta-LK-KumarNeural",
    ],
    SK: ["sk-SK-ViktoriaNeural", "sk-SK-LukasNeural"],
    SI: ["sl-SI-PetraNeural", "sl-SI-RokNeural"],
    SO: ["so-SO-UbaxNeural", "so-SO-MuuseNeural"],
    AL: ["sq-AL-AnilaNeural", "sq-AL-IlirNeural"],
    "LATN-RS": ["sr-Latn-RS-NicholasNeural", "sr-Latn-RS-SophieNeural"],
    RS: ["sr-RS-SophieNeural", "sr-RS-NicholasNeural"],
    SE: ["sv-SE-SofieNeural", "sv-SE-MattiasNeural", "sv-SE-HilleviNeural"],
    TH: ["th-TH-PremwadeeNeural", "th-TH-NiwatNeural", "th-TH-AcharaNeural"],
    TR: ["tr-TR-EmelNeural", "tr-TR-AhmetNeural"],
    UA: ["uk-UA-PolinaNeural", "uk-UA-OstapNeural"],
    PK: ["ur-PK-UzmaNeural", "ur-PK-AsadNeural"],
    UZ: ["uz-UZ-MadinaNeural", "uz-UZ-SardorNeural"],
    VN: ["vi-VN-HoaiMyNeural", "vi-VN-NamMinhNeural"],
    CN: [
        "wuu-CN-XiaotongNeural",
        "wuu-CN-YunzheNeural",
        "yue-CN-XiaoMinNeural",
        "yue-CN-YunSongNeural",
        "zh-CN-XiaoxiaoNeural",
        "zh-CN-YunxiNeural",
        "zh-CN-YunjianNeural",
        "zh-CN-XiaoyiNeural",
        "zh-CN-YunyangNeural",
        "zh-CN-XiaochenNeural",
        "zh-CN-XiaohanNeural",
        "zh-CN-XiaomengNeural",
        "zh-CN-XiaomoNeural",
        "zh-CN-XiaoqiuNeural",
        "zh-CN-XiaoruiNeural",
        "zh-CN-XiaoshuangNeural",
        "zh-CN-XiaoxuanNeural",
        "zh-CN-XiaoyanNeural",
        "zh-CN-XiaoyouNeural",
        "zh-CN-XiaozhenNeural",
        "zh-CN-YunfengNeural",
        "zh-CN-YunhaoNeural",
        "zh-CN-YunxiaNeural",
        "zh-CN-YunyeNeural",
        "zh-CN-YunzeNeural",
        "zh-CN-XiaorouNeural",
        "zh-CN-YunjieNeural",
    ],
    "CN-GUANGXI": ["zh-CN-guangxi-YunqiNeural"],
    "CN-henan": ["zh-CN-henan-YundengNeural"],
    "CN-liaoning": [
        "zh-CN-liaoning-XiaobeiNeural",
        "zh-CN-liaoning-YunbiaoNeural",
    ],
    "CN-shaanxi": ["zh-CN-shaanxi-XiaoniNeural"],
    "CN-shandong": ["zh-CN-shandong-YunxiangNeural"],
    "CN-sichuan": ["zh-CN-sichuan-YunxiNeural"],
    TW: ["zh-TW-HsiaoChenNeural", "zh-TW-YunJheNeural", "zh-TW-HsiaoYuNeural"],
};

export { languages, lang2abb, lang2name, name2reg, reg2voice };
