import {
    languages,
    lang2abb,
    lang2name,
    name2reg,
    reg2voice,
} from "./languages.js";
import "./App.css";
import { useEffect, useState } from "react";
import { getTokenOrRefresh } from "./token.js";

const speechsdk = require("microsoft-cognitiveservices-speech-sdk");
const axios = require("axios").default;
const { v4: uuidv4 } = require("uuid");

function App() {
    const [regions, set_regions] = useState([]);
    const [voices, set_voices] = useState([]);

    const [curr_language, set_curr_language] = useState("Afrikaans");
    const [curr_region, set_curr_region] = useState("");
    const [curr_voice, set_curr_voice] = useState("");

    const [region_options, set_reg_options] = useState([]);
    const [voice_options, set_voice_options] = useState([]);
    const lang_selections = [];

    const [asr_text, set_asr_text] = useState("");
    const [translated_text, set_translated_text] = useState("");
    const [filename, set_filename] = useState("");
    const [speak_button_class, set_speak_button_class] = useState("");

    const [display_text, set_display_text] = useState("");
    const [player, updatePlayer] = useState({ p: undefined, muted: false });

    languages.map((lang) => {
        lang_selections.push(<option value={lang}>{lang}</option>);
    });

    useEffect(() => {
        set_regions(lang2name[curr_language]);
    }, []);

    useEffect(() => {
        let select = [];
        regions.map((region) => {
            select.push(<option value={region}>{region}</option>);
        });
        set_reg_options(select);

        set_curr_region(regions[0]);
        let reg_abb = name2reg[regions[0]];
        if (reg_abb) {
            set_voices(reg2voice[reg_abb]);
        }
    }, [regions]);

    useEffect(() => {
        let select = [];
        voices.map((voice) => {
            select.push(<option value={voice}>{voice}</option>);
        });
        set_voice_options(select);
        set_curr_voice(voices[0]);
    }, [voices]);

    function change_lang(obj) {
        set_curr_language(obj.target.value);
        if (lang2name[obj.target.value]) {
            set_regions(lang2name[obj.target.value]);
        } else {
            set_regions([]);
        }
    }

    function change_region(obj) {
        set_curr_region(obj.target.value);
        let reg_abb = name2reg[obj.target.value];
        if (reg_abb) {
            set_voices(reg2voice[reg_abb]);
        }
    }

    function change_voice(obj) {
        set_curr_voice(obj.target.value);
    }

    useEffect(() => {}, [speak_button_class]);

    // async function play_translation() {
    //     const response = await axios.get("/api/replay");
    //     if (response.status === 200) {
    //         const json = await response.json();
    //     } else {
    //         console.log("error replaying file");
    //     }
    // }

    async function translate() {
        set_speak_button_class("active");
        set_asr_text("");
        set_translated_text("");
        set_display_text("");
        let lang_abb = lang2abb[curr_language];

        //asr
        const tokenObj = await getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
            tokenObj.asr_authToken,
            tokenObj.region
        );
        speechConfig.speechRecognitionLanguage = "en-US";

        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        // const audioConfig =
        // speechsdk.AudioConfig.fromWavFileInput("./test.wav");
        const recognizer = new speechsdk.SpeechRecognizer(
            speechConfig,
            audioConfig
        );

        let recognized_text = "";
        recognizer.recognizeOnceAsync((result) => {
            if (result.reason === speechsdk.ResultReason.RecognizedSpeech) {
                recognized_text = result.text;
                set_asr_text(recognized_text);
                set_speak_button_class("");

                //mt
                let endpoint = "https://api.cognitive.microsofttranslator.com";
                let translated_text;
                axios({
                    baseURL: endpoint,
                    url: "/translate",
                    method: "post",
                    headers: {
                        Authorization: tokenObj.mt_authToken,
                        "Ocp-Apim-Subscription-Region": tokenObj.region,
                        "Content-type": "application/json",
                        "X-ClientTraceId": uuidv4().toString(),
                    },
                    params: {
                        "api-version": "3.0",
                        from: "en",
                        to: lang_abb,
                    },
                    data: [
                        {
                            text: recognized_text,
                        },
                    ],
                    responseType: "json",
                }).then(async function (response) {
                    console.log(response.data);
                    translated_text = response.data[0].translations[0].text;
                    set_translated_text(translated_text);

                    const toSpeechConfig =
                        speechsdk.SpeechConfig.fromAuthorizationToken(
                            tokenObj.asr_authToken,
                            tokenObj.region
                        );

                    toSpeechConfig.speechSynthesisVoiceName = curr_voice;

                    const toAudioConfig =
                        speechsdk.AudioConfig.fromDefaultSpeakerOutput();

                    let synthesizer = new speechsdk.SpeechSynthesizer(
                        toSpeechConfig,
                        toAudioConfig
                    );

                    const textToSpeak = translated_text;
                    synthesizer.speakTextAsync(
                        textToSpeak,
                        async (result) => {
                            let text;
                            if (
                                result.reason ===
                                speechsdk.ResultReason
                                    .SynthesizingAudioCompleted
                            ) {
                                text = `synthesis finished for "${textToSpeak}".\n`;
                                set_display_text(text);
                                synthesizer.close();
                                synthesizer = undefined;
                            } else if (
                                result.reason ===
                                speechsdk.ResultReason.Canceled
                            ) {
                                text = `synthesis failed. Error detail: ${result.errorDetails}.\n`;
                                set_display_text(text);
                                synthesizer.close();
                                synthesizer = undefined;
                            }
                        },
                        function (err) {
                            console.log(err);
                            synthesizer.close();
                            synthesizer = undefined;
                        }
                    );
                });
            } else {
                set_speak_button_class("");
                set_display_text(
                    "ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly."
                );
                return;
            }
        });
    }

    return (
        <>
            <h1 id="title">SPEECH TO SPEECH TRANSLATION</h1>

            <div id="selections">
                <div className="select-group">
                    <label for="languages">Language: </label>
                    <select
                        name="languages"
                        id="languages"
                        onChange={(obj) => change_lang(obj)}
                    >
                        {lang_selections}
                    </select>
                </div>

                <div className="select-group">
                    <label for="region">Region: </label>
                    <select
                        name="region"
                        id="region"
                        onChange={(obj) => change_region(obj)}
                    >
                        {region_options}
                    </select>
                </div>

                <div className="select-group">
                    <label for="voice">Voice: </label>
                    <select
                        name="voice"
                        id="voice"
                        onChange={(obj) => change_voice(obj)}
                    >
                        {voice_options}
                    </select>
                </div>
            </div>

            <div id="main-container">
                <div id="speak-button-container">
                    <p id="speak-label">Press button and speak into mic</p>
                    <button
                        id="speak-button"
                        className={"" + speak_button_class}
                        onClick={() => translate()}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            fill="currentColor"
                            class="bi bi-mic"
                            viewBox="0 0 16 16"
                        >
                            <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5" />
                            <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3" />
                        </svg>
                    </button>
                </div>

                <div id="asr-container">
                    <p>{display_text}</p>
                    <label className="asr-label">Recognized Text: </label>
                    <p className="asr-text">{asr_text}</p>
                    <label className="asr-label">Translated Text: </label>
                    <p className="asr-text">{translated_text}</p>
                </div>

                {/* {filename !== "" ? (
                    <button
                        id="replay-button"
                        onClick={() => play_translation("")}
                    >
                        Replay Translation
                    </button>
                ) : (
                    <></>
                )} */}
            </div>
        </>
    );
}

export default App;
